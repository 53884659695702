//import React, { useState } from 'react';
//import { useEffect } from 'react';
import HTMLFlipBook from "react-pageflip";
import Image from 'react-bootstrap/Image'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Col, Container, Row } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { NieveComponente } from './components/NieveComponente'


import './App.js';
import './index.js';
import './Magazine.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";



import imagef from "./imagenes/IcoFacebook.svg"
import imagei from "./imagenes/IcoInstagram.svg"
import imaget from "./imagenes/IcoTwitter.svg"
import finallogo from "./imagenes/Logofooter.png"

import image1 from "./imagenes/logosup.png"

function Privacidad() {
    const imageClick = () => {
        console.log('Click');
      }
  
    return (
        <div className="Privacidad">
            <div className='img-sup-magazine'>
           <Container id="menumagazine">
           <NieveComponente />
              <Navbar collapseOnSelect expand="lg"  className="justify-content-center" id="image-menu" >
                <Nav className="mr-auto">
                  <Nav.Link href="?h=app">
                    <img src={image1} width={200} alt=""/>
                  </Nav.Link>
                </Nav>
                    <Nav defaultActiveKey="/home" as="ul" expand="lg" className="justify-content-center" id="contmenuN">
                      <Nav.Item as="li">
                        <Nav.Link href="?h=app" className="menu" id="menuizquierda">Inicio</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey="link-4" href="https://wa.me/5215612818956" className="menu" id="contacto">Contacto</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div className="icons-redes">
                    <Nav >
                      <Nav.Link>
                        <img src={imagef} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                      </Nav.Link>
                      <Nav.Link>
                        <img src={imagei} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                      </Nav.Link>
                      <Nav.Link>
                        <img src={imaget} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                      </Nav.Link>
                    </Nav> 
                    </div>
              </Navbar>
            </Container>
            <Container  id="menumagazineresponsiveM">
            <Navbar collapseOnSelect expand="lg"  className="justify-content-center">
                <Navbar.Collapse id="responsive-navbar-nav">
                </Navbar.Collapse>
                <Nav className="mr-auto">
                  <Nav.Link>
                    <img src={image1} width={280} alt=""/>
                  </Nav.Link>
                </Nav>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav.Link >
                    </Nav.Link>
                  </Nav>

                </Navbar.Collapse>
              </Navbar>
              <Nav defaultActiveKey="/home" as="ul" expand="lg" className="justify-content-center" id="contmenuM">
              <Nav.Item as="li">
                <Nav.Link href="?h=app" className="menuM" id="menuizquierdaM">Inicio</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="link-4" href="https://wa.me/5215612818956" className="menuM">Contacto</Nav.Link>
              </Nav.Item>
            </Nav>
            </Container>
            </div>
            <Container>
            <br></br>
            <br></br>
                <div>
                    <div align="center"><b>Aviso de Privacidad</b></div>
                    
                    <a>
                
Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en adelante la “Ley”) hacemos de su conocimiento que Proyecto Higo, S.A. de C.V. (“Higo”), es una editora de contenidos, en particular de revistas físicas y/o digitales, con domicilio en Avenida Ruiz Cortines Manzana 1, Lote 20, PH, Colonia Fraccionamiento las Margaritas, C.P. 52977, Municipio de Atizapán de Zaragoza, Estado de México, la cual por sí misma o por conducto de cualquiera de sus empresas relacionadas, filiales o subsidiarias, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
<br></br>
A través del presente hacemos de su conocimiento que su información personal será utilizada para las finalidades que adelante se relacionan:
<br></br>
a) Para fines de la relación que entablemos con usted:
<br></br>
1.	Recaba sus datos para verificar su identidad.<br></br>
2.	Dar cumplimiento a las obligaciones que se desprendan de la relación jurídica que entablemos con usted.<br></br>
3.	Observar debidamente nuestra política de Identificación.<br></br>
4.	Realizar estudios y actos propios de nuestras operaciones.<br></br>
5.	Proveer servicios y productos que solicite.<br></br>
<br></br>
b) Para fines mercadológicos:
<br></br>
1.	Notificarle sobre nuevos servicios o productos los cuales consideremos puedan ser de su interés o bien comunicarle cambios en los mismos.<br></br>
2.	Realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los mismos.<br></br>
3.	Formar parte de nuestra(s) base(s) de datos.<br></br>
4.	Para fines estadísticos, publicitarios, promocionales, mercadológicos o de prospección comercial<br></br>
<br></br>
Para las finalidades antes mencionadas, requerimos obtener datos personales de identificación, así como los documentos oficiales con los que compruebe dichos datos, tales como:
<br></br>
a.	Nombre completo, esto es, apellido paterno, materno y nombre (s), sin abreviaturas.<br></br>
b.	Fecha de Nacimiento.<br></br>
c.	Nacionalidad.<br></br>
d.	País de Nacimiento.<br></br>
e.	Clave Única de Registro de Población.<br></br>
f.	Domicilio actual.<br></br>
g.	Registro Federal de Contribuyentes.<br></br>
i.	Actividad, ocupación o giro mercantil.<br></br>
j.	Datos de contacto, tales como, número de teléfono fijo, móvil y correo electrónico.
<br></br>
La información que Higo recibe y almacena a través de nuestras aplicaciones, página de internet y redes sociales cuando usted interactúa con las mismas es principalmente información técnica, como dirección web de la página que está visitando, su dirección IP, su tipo de navegador y sistema operativo.
<br></br>
Asimismo, le informarmos que la información obtenida mediante Facebook es únicamente para uso estadístico, sin ningún fin de publicidad o uso distinto al estadístico.
<br></br>
Usted cuenta con 03 días para dar aviso a Higo, conforme al procedimiento establecido en el presente Aviso, de su oposición al tratamiento de los datos que no sean esenciales para la relación jurídica que entable con Higo.
<br></br>
Toda la información proporcionada y sus datos personales formarán parte de la(s) base(s) de datos propiedad de Higo, y podrán transmitirse, para los mismos fines establecidos en el presente aviso, a cualquiera de sus empresas relacionadas, filiales o subsidiarias. Dicha información deberá ser actualizada y será almacenada y protegida con medidas de seguridad administrativas, técnicas y físicas que eviten daños, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado de la misma.
<br></br>
De conformidad con los artículos del 28 al 35 de la Ley, usted tiene derecho al Acceso, Rectificación y Cancelación de sus datos personales, a Oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos ha otorgado, salvo lo dispuesto por el artículo 26 de dicho ordenamiento.
<br></br>
Para los fines señalados en el párrafo anterior, deberá enviar una solicitud por escrito, a nuestra Área de Manejo de Información ubicada en Avenida Ruiz Cortines Manzana 1, Lote 20, PH, Colonia Fraccionamiento las Margaritas, C.P. 52977, Municipio de Atizapán de Zaragoza, Estado de México o vía correo electrónico a la dirección contacto@vivesmeralda.com. 
<br></br>
Una vez recibida su solicitud, nos pondremos en contacto con usted vía correo electrónico, en un plazo no mayor a 20 días, a fin de comunicarle la procedencia de su solicitud.
<br></br>
Higo podrá realizar transferencias de datos por disposición de Ley o Tratados en los que México sea parte, o cuando sea requerido por autoridad competente.
<br></br>
En términos del artículo 8 de la Ley y el artículo 13 del Reglamento, en virtud de que el tipo de datos que se recaban conforme al presente Aviso de Privacidad no requieren el consentimiento expreso por parte del titular, se entenderá que consiente tácitamente el tratamiento de sus datos, cuando habiéndose puesto a disposición el presente Aviso de Privacidad, no manifieste su oposición.
<br></br>
Importante: Nos reservamos el derecho de efectuar en cualquier momento modificaciones al presente Aviso. Dichas modificaciones serán notificadas en nuestros sitios web y/o aplicaciones y/o redes sociales y/o a través de su correo electrónico.


                    </a>
                </div>
            </Container>

            <Container className="cont-final">
        <Container>
          <Row>
            <Col>
              <div  className="cont-logo-footer">
                <img src={finallogo} alt=""/>
              </div>
              <div className="text-logo-foot">
                <p><strong>Todos los derechos reservados, ©2024 Vive Esmeralda</strong></p>
              </div>
            </Col>
            <Col>
              <div>
                <Nav className="icons-redes-ultimos">
                  <Nav.Link  className="">
                    <img src={imagef} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imagei} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imaget} width={30} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                  </Nav.Link>
                </Nav>
              </div>
              <div className="text-contacto-final">
                <p><strong>Contacto</strong></p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* responsivo*/}

      <Container  className="container-final-responsive">
        <Row>
          <Col>
            <div  className="logo-responsive">
              <img src={finallogo} alt="" width={150}/>
            </div> 
            <div>
              <Nav className="icons-redes-ultimos">
                <Nav.Link  className="">
                  <img src={imagef} width={32} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                </Nav.Link>
                <Nav.Link>
                  <img src={imagei} width={32} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                </Nav.Link>
                <Nav.Link>
                  <img src={imaget} width={32} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                </Nav.Link>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="derechos-reservados-resposive">
            <p><strong>Todos los derechos reservados, ©2024 Vive Esmeralda</strong></p>
          </div>
        </Row>
      </Container>
        </div>
    );
}
export default Privacidad;

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Carousel from 'react-bootstrap/Carousel'
import React, { useState } from 'react';
import { useEffect } from 'react';
import InfiniteCarousel from 'react-leaf-carousel';
import { NieveComponente } from './components/NieveComponente'

import './App.js';
import './index.js';
import './Videos.css';

import image1 from "./imagenes/logosup.png"
import imgfondovideos from "./imagenes/imgfondo.png"
import imagef from "./imagenes/IcoFacebook.svg"
import imagei from "./imagenes/IcoInstagram.svg"
import imaget from "./imagenes/IcoTwitter.svg"
import finallogo from "./imagenes/Logofooter.png"

function Videos() {
    const [lista1, setLista1] = useState([]);
    const [lista2, setLista2] = useState([]);
    const [lista3, setLista3] = useState([]);

    const imageClick = () => {
      console.log('Click');
    }

    const handleVideoslista1 = () => {
    setLista1(
        <InfiniteCarousel
                    breakpoints={[
                      {
                        breakpoint: 500,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                        },
                      },
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                    ]}
                    /*dots={true}
                    showSides={true}*/
                    sidesOpacity={.5}
                    /*sideSize={.1}*/
                    slidesToScroll={1}
                    slidesToShow={4}
                    scrollOnDevice={true} 
                    >
                       
                       <div className='video1'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/0ttUnW9pC2Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div  className='video2'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/cynUVjyRFNQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div  className='video3'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/C54K4azqYwM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div  className='video4'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/ibR6CQemcRU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div  className='video5'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/tOQzHJaKryE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>   
                
                  </InfiniteCarousel>);
    };
    const handleVideoslista2 = () => {
    setLista2(
    <InfiniteCarousel
                breakpoints={[
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                ]}
                /*dots={true}
                showSides={true}*/
                sidesOpacity={.5}
                /*sideSize={.1}*/
                slidesToScroll={1}
                slidesToShow={4}
                scrollOnDevice={true} 
                >

                <div className='video1'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/9oGYnh689as?si=8c7T2e4Evc5NWh3r" allowfullscreen></iframe>
                </div>
                <div  className='video2'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/592pwoVrqCY?si=ALII547X5b590vd9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div  className='video3'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/KP_NQ7f3uTI?si=h2Jcqh8kvpbwptD6" allowfullscreen></iframe>
                </div>
                <div  className='video4'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/odn3aqUf9hs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div  className='video5'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/_dcWDHDgey8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>   

              </InfiniteCarousel>);
    };
    const handleVideoslista3 = () => {
setLista3(
    <InfiniteCarousel
                breakpoints={[
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                ]}
                /*dots={true}
                showSides={true}*/
                sidesOpacity={.5}
                /*sideSize={.1}*/
                slidesToScroll={1}
                slidesToShow={4}
                scrollOnDevice={true} 
                >
                <div className='video1'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/ajtl6bUV6vE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div  className='video2'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/rgQL5Puw-e8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div  className='video3'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/NkmhzxCRy2M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div  className='video4'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/PiVUUFyzR4E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div  className='video5'>
                <iframe width="100%" height="50%" src="https://www.youtube.com/embed/EpydKUZ4oGg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>   
              </InfiniteCarousel>);
};
    useEffect(() => {
        handleVideoslista1();
        handleVideoslista2();
        handleVideoslista3();
      }, []);
    return (
        <div className="Videos">
                    <Container className='seccion-1'>
                    <NieveComponente />
                    <div className='img-sup-magazine'>
           <Container id="menumagazine">
              <Navbar collapseOnSelect expand="lg"  className="justify-content-center" id="image-menu" >
                <Nav className="mr-auto">
                  <Nav.Link href="?h=app">
                    <img src={image1} width={200} alt=""/>
                  </Nav.Link>
                </Nav>
                    <Nav defaultActiveKey="/home" as="ul" expand="lg" className="justify-content-center" id="contmenuN">
                      <Nav.Item as="li">
                        <Nav.Link href="?h=app" className="menu" id="menuizquierda">Inicio</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey="link-4" href="https://wa.me/5215612818956" className="menu" id="contacto">Contacto</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div className="icons-redes">
                    <Nav >
                      <Nav.Link>
                        <img src={imagef} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                      </Nav.Link>
                      <Nav.Link>
                        <img src={imagei} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                      </Nav.Link>
                      <Nav.Link>
                        <img src={imaget} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                      </Nav.Link>
                    </Nav> 
                    </div>
              </Navbar>
            </Container>
            <Container  id="menuresposiveV">
            <Navbar collapseOnSelect expand="lg"  className="justify-content-center">
                <Navbar.Collapse id="responsive-navbar-nav">
                </Navbar.Collapse>
                <Nav className="mr-auto">
                  <Nav.Link href="?h=app" >
                    <img src={image1} width={280} alt=""/>
                  </Nav.Link>
                </Nav>
              </Navbar>
              <Nav defaultActiveKey="/home" as="ul" expand="lg" className="justify-content-center" id="contmenuV">
              <Nav.Item as="li">
                <Nav.Link href="?h=app" className="menuV" id="menuizquierdaV">Inicio</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="link-4" className="menuV" href="https://wa.me/5215612818956" id="contactoV">Contacto</Nav.Link>
              </Nav.Item>
            </Nav>
            </Container>
            </div>
          </Container>
            <Container className='contenedor-videos'>
                <Row className='box-video-principal'>
                    <Col>
                        <div className='box-text-principal'>
                            <p className='text-video-principal'>¡NUEVO VIDEO!</p>
                        </div>
                        <div className='video-principal'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/LJoU4mmn0k0?si=xf1nC66W2V4Mz5VC"  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </Col>
                </Row>
                <Row  className='box-text-sugerencia'>
                    <div>
                        <p className='text-sugerencias'>Encuentra contenido de tus secciones favoritas</p>
                    </div>
                </Row>
                <div className='container-videos-secciones'>
                    <div className='box-text-list'>
                        <p className='text-list-videos'>Ícono</p>
                    </div>
                <div className='videos-listas1'>
                        {lista1}
                </div>
                </div>
                <div className='container-videos-secciones'>
                <div className='box-text-list'>
                    <p className='text-list-videos'>Soy vecino</p>
                </div>
                <div className='videos-listas2'>
                        {lista2}
                </div>
                </div>
                <div className='container-videos-secciones'>
                <div className='box-text-list'>
                    <p  className='text-list-videos'>Entretenimiento</p>
                </div>
                <div className='videos-listas3'>
                        {lista3}
                </div>
                </div>
            </Container>
            <Container className="cont-final">
        <Container>
          <Row>
            <Col>
              <div className="text-privacidad">
                <ul>
                <strong>
                    {/*<li>Quiénes somos</li>*/}
                    <a  href="?a=privacidad"  rel="noopener noreferrer">
                     <li>Aviso de Privacidad</li>
                    </a>
                    {/*<a href="https://vivesmeralda.com/privacidad.pdf" rel="noopener noreferrer">
                      <li>Legales</li>
                    </a>*/}
                  </strong>
                </ul>
              </div>
            </Col>
            <Col>
              <div  className="cont-logo-footer">
                <img src={finallogo} alt=""/>
              </div>
              <div className="text-logo-foot">
                <p><strong>Todos los derechos reservados, ©2024 Vive Esmeralda</strong></p>
              </div>
            </Col>
            <Col>
              <div>
                <Nav className="icons-redes-ultimos">
                  <Nav.Link  className="">
                    <img src={imagef} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imagei} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imaget} width={30} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                  </Nav.Link>
                </Nav>
              </div>
              <div className="text-contacto-final">
                <p><strong>Contacto</strong></p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* responsivo*/}
      <Container  className="container-final-responsive">
        <Row>
          <Col className="privacidad-responsive">
            <ul>
            <strong>
                    {/*<li>Quiénes somos</li>*/}
                    <a  href="?a=privacidad"  rel="noopener noreferrer">
                     <li>Aviso de Privacidad</li>
                    </a>
                    {/*<a href="https://vivesmeralda.com/privacidad.pdf" rel="noopener noreferrer">
                      <li>Legales</li>
                    </a>*/}
             </strong>
            </ul>
          </Col>
          <Col>
            <div  className="logo-responsive">
              <img src={finallogo} alt="" width={150}/>
            </div> 
            <div>
              <Nav className="icons-redes-ultimos">
                <Nav.Link  className="">
                  <img src={imagef} width={32} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                </Nav.Link>
                <Nav.Link>
                  <img src={imagei} width={32} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                </Nav.Link>
                <Nav.Link>
                  <img src={imaget} width={32} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                </Nav.Link>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="derechos-reservados-resposive">
            <p><strong>Todos los derechos reservados, ©2024 Vive Esmeralda</strong></p>
          </div>
        </Row>
      </Container>
        </div>
    );
}
export default Videos;
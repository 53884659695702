import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Carousel from 'react-bootstrap/Carousel'
import React, { useState } from 'react';
/*import Figure from 'react-bootstrap/Figure'*/
import Image from 'react-bootstrap/Image'
import InfiniteCarousel from 'react-leaf-carousel';
//import ReactPlayer from 'react-player'
import Card from 'react-bootstrap/Card'
import axios from 'axios';
/*import React, { useEffect, useState } from 'react';*/
import { useEffect } from 'react';
import { NieveComponente } from './components/NieveComponente'

import './App.css';
import './Notes.js';
import './Magazine.js';
import './Videos.js';

import image1 from "./imagenes/logosup.png"
import imagef from "./imagenes/IcoFacebook.svg"
import imagei from "./imagenes/IcoInstagram.svg"
import imaget from "./imagenes/IcoTwitter.svg"
//import imgcarrousel1 from "./imagenes/imsupcarrousel.png"
//import imgcarrousel12 from "./imagenes/p2.jpg"
//import imgcarrousel13 from "./imagenes/p3.jpg"
//import bansup1 from "./imagenes/Bansup1.png"
//import local1 from "./imagenes/Locales1.png"
import picturemonth from "./imagenes/descargarevistavive.png"
//import picture1mundo from "./imagenes/imgestasemana.png"
//import bander1 from "./imagenes/Bander1.png"
//import bander2 from "./imagenes/Bander2.png"
//import baninf1 from "./imagenes/Baninf1.png"
//import baninf2 from "./imagenes/Baninf2.png"
import linksate from "./imagenes/llinksate.png"
import icotelefono from "./imagenes/IcoTelefono.svg"
import icowhatsapp from "./imagenes/IcoWhatsapp.svg"
import icoemail from "./imagenes/IcoEmail.svg"
import blackicofacebook from "./imagenes/Black-IcoFacebook.svg"
import blackicoinstagram from "./imagenes/Black-IcoInstagram.svg"
import blackicotwitter from "./imagenes/Black-IcoTwitter.svg"
import finallogo from "./imagenes/Logofooter.png"

axios.defaults.baseURL = 'https://vivesmeralda.com/api/rest/v2/web/home?limit=16&sectionId=2&cache=false&principalLimit=10&location=2';

function App() {
    const [catalogo, setCatalogo] = useState([]);
    const [index, setIndex] = useState(0);
    const imageClick = () => {
      console.log('Click');
    }
    const [primera, setPrimera] = useState([]);
    const [segunda, setSegunda] = useState([]);
    const [segundaresponsive, setSegundareponsive] = useState([]);
    const [tercera, setTercera] = useState([]);
    const [cuarta, setCuarta] = useState([]);
    const [anunciosegunda, setAnunciosegunda] = useState([]);
    const [anuncioprimera, setAnuncioprimera] = useState([]);
    const [anunciotercera, setAnunciotercera] = useState([]);
    const [anuncioquinta, setAnuncioquinta] = useState([]);
    const [puedeinteresarte, setPuedeinteresarte] = useState([]);
    const [puedeinteresarte2, setPuedeinteresarte2] = useState([]);
    //const [datehome, setDatehome] = useState([]);
    const [datehometwo, setDatehometwo] = useState([]);
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    //key=API_KEY. = "AIzaSyBPLsEF0JKPmxHD8HV5HFi7ygy4S-rUQhc"

    const fetchData = () => {
      axios
          .get('https://vivesmeralda.com/api/rest/v2/web/home?limit=16&sectionId=2&cache=false&principalLimit=10&location=2')
          .then((contents) => {
            setCatalogo(contents.data.contents);
                  setPrimera(contents.data.contents.slice(0, 5));
                  setSegundareponsive(contents.data.contents.slice(5, 10));
                  setTercera(contents.data.contents.slice(4, 6));
                  setCuarta(contents.data.contents.slice(13, 15));
                  //////////////////////
                  setAnuncioprimera(contents.data.campaignsSpace10);
                  setAnunciosegunda(contents.data.campaignsSpace9);
                  setAnunciotercera(contents.data.campaignsSpace13);
                  setAnuncioquinta(contents.data.campaignsSpace2);
                  //////////////////////
                  setPuedeinteresarte(contents.data.contents.slice(8,12));
                  setPuedeinteresarte2(contents.data.contents.slice(12,16));
                  /////////////////////
                  console.log(contents);
                  console.log(contents.data.contents);
                  console.log(primera);

                  //setDatehome(contents.data.contents.slice(0).map((item)=>(item.createdTime)));
                  //setDatehome(contents.data.contents.slice(3, 8));
                  //setDatehometwo(contents.data.contents.slice(0))
                  //console.log(datehometwo)

                  var datevisualed = new Date(parseInt(contents.data.contents.slice(3,8).map((item)=>(item.createdTime))))
                  setDatehometwo(datevisualed)
                  console.log(datevisualed)
                 console.log(datehometwo)

                  //console.log(datehome)
                  
                  /*var datevisualed = new Date(parseInt(contents.data.contents.createdTime))
                  console.log(datevisualed)
                  setDate(datevisualed.getDate() + "/"+datevisualed.getMonth() + "/"+datevisualed.getFullYear())*/

                  setSegunda(<InfiniteCarousel
                    breakpoints={[
                      {
                        breakpoint: 500,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                        },
                      },
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                    ]}
                    /*dots={true}
                    showSides={true}*/
                    sidesOpacity={.5}
                    /*sideSize={.1}*/
                    slidesToScroll={1}
                    slidesToShow={4}
                    scrollOnDevice={true} 
                    >
                    {contents.data.contents.slice(3, 8).map((item)=>(
                      <a href={"?p=notes&id=" + item.contentId}  rel="noopener noreferrer">
                      <div key={item.title}>
                        <img
                          alt=""
                          src={item.image}
                        />
                        <p className="date">{item.datehometwo}</p>
                        <p className="text-carrousel-locales">{item.title}</p>
                      </div>
                      </a>
                    ))}
                  </InfiniteCarousel>)
          })
          .catch((err) => {
              console.log(err);
          });
  };
  useEffect(() => {
    fetchData();
  }, []);
    
  return (
    <div className="App">
      {/*seccion-1*/}
      <Container className="seccion-1">
      <NieveComponente />
      <div id="fixed">
        <Row>
          <Col>
            <Container>
              <Navbar collapseOnSelect expand="lg"  className="justify-content-center">
                <Navbar.Collapse id="responsive-navbar-nav">
                </Navbar.Collapse>
                <Nav className="mr-auto">
                  <Nav.Link href="?h=app">
                    <img src={image1} width={240} alt=""/>
                  </Nav.Link>
                </Nav>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav.Link >
                    </Nav.Link>
                  </Nav>
                  <Nav className="icons-redes">
                    <Nav.Link>
                      <img src={imagef} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                    </Nav.Link>
                    <Nav.Link>
                      <img src={imagei} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                    </Nav.Link>
                    <Nav.Link>
                      <img src={imaget} width={"30"} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Container>
          </Col>
        </Row> 
        <Row>
          <Col>
            <Nav defaultActiveKey="" as="ul" expand="lg" className="justify-content-center" id="contmenuA">
              <Nav.Item as="li">
                <Nav.Link href="#locales"  className="menu" id="menuizquierdaA">Locales</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="#estasemana" className="menu" id="mundomenuA">Mundo</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="?v=videos" eventKey="link-2" className="menu" id="videosA">Videos</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="#contactofoot"  className="menu" id="contactoA">Contacto</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        </div>
        <Row>
          <Col>
            <Container className="cont-img-estados">
              <Carousel fade className="carousel-flechas"activeIndex={index} onSelect={handleSelect}>
                {primera.map((item)=>(
                  <Carousel.Item> 
                    <a href={"?p=notes&id=" + item.contentId} rel="noopener noreferrer">
                    <Image
                      className="d-block w-100"
                      src={item.image}
                      alt=""
                      style={{ width: "100%", height: "50%" }}
                    fluid/>

                    <Carousel.Caption className="cont-estados">
                      <p><strong>{item.title}</strong></p>
                    </Carousel.Caption>
                    </a>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className="seccion-2">
        <Container className="cont-seccion-2">
          <Row >
            <Col>
              <Carousel fade>
                {anuncioprimera.map((item)=>(
                  <Carousel.Item interval={2000}>
                    <a href={item.forward} rel="noopener noreferrer">
                      <div>
                      <img
                        className=""
                        src={item.image}
                        alt=""
                        width="150%"
                        height="110%"
                      />
                      </div>
                    </a>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="seccion-3">
        <Row>
          <Col className="cont-box-locales">
            <div className="box-locales">
            <a name="locales" id="locales"><p>LOCALES</p> </a>
            </div>
            {segunda}
          </Col>
        </Row>
  {/*container for responsive*/}
        <Row>
          <Col className="box-locales-responsive">
            <div className="box-locales">
              <a name="locales" id="locales"><p>LOCALES</p></a>
            </div>
            <Container>
              {segundaresponsive.map((item)=>(
                <Row className="cont-notes">
                  <Col>
                  <a href={"?p=notes&id=" + item.contentId}  rel="noopener noreferrer">
                    <div  className="cont-notes-responsive">
                      <img
                        alt=""
                        src={item.image}
                        width="80%"
                      />
                      <div className="cont-text-notes">
                        <p className="date"></p>
                        <p className="text-carrousel-locales">{item.title}</p>
                      </div>
                    </div>
                    </a>
                  </Col>
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      </Container>
   {/*this is the 4 container*/}
      <Container className="cont-player">
        <Container className="cont-player-2">
          <Row className='contvideoimg'>
            <Col>
            <div id="player"></div>
              <div className="cont-player-3">
              <iframe id="ytplayer" name="ytplayer" type="text/html" width="600" height="360" src="https://www.youtube.com/embed/52zDZ_Q2BQY?si=cpRz4ISdZs5Xuphr"
                title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
                encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>
              </div>
            </Col>
            <Col>
              <a href="?m=magazine" rel="noopener noreferrer">
                <Image src={picturemonth}
                  width=""
                  height="78%"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
  {/*container player responsive*/}
      <Container className="cont-player-responsive">
        <Row>
          <Col className="cont-video-backgroud">
            <div>
              <p>Videos</p>
            </div>
            <div className="cont-player-3-responsive">
            <iframe id="ytplayer" name="ytplayer" type="text/html" width="95%" height="90%" src="https://www.youtube.com/embed/52zDZ_Q2BQY?si=cpRz4ISdZs5Xuphr"
                title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
                encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="cont-image-player-responsive">
          <a href="?m=magazine"  rel="noopener noreferrer">
            <Image src={picturemonth}
              width=""
              height=""
            />
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="two-anuncio-responsive">
            <Carousel fade>
              {anunciosegunda.map((item)=>(
                <Carousel.Item interval={2000}>
                  <a href={item.forward} rel="noopener noreferrer">
                    <div>
                    <img
                      className=""
                      src={item.image}
                      alt=""
                      width="100%"
                    />
                    </div>
                  </a>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
      <Container ClassName="seccion-4" id="mundo">
        <Row className="container-seccion-4">
          <Col className="justify-content-center">
            <div className="box-estasemana">
            <a name="estasemana" id="estasemana"><p>ESTA SEMANA</p></a>
            </div>
            {tercera.map((item)=>(
              <Row className="cont-notes">
                <div>
                  <Card className="img-seccion4">
                    <Card.Img variant="middle" src={item.image} width={400}/>
                  </Card>
                </div>
                <a href={"?p=notes&id=" + item.contentId}  rel="noopener noreferrer">
                  <div>
                    <Card.Body>  
                      <Card.Title>
                        <strong>{item.title}</strong>
                      </Card.Title>
                        <Card.Text whidth="281px">
                            {item.description}
                        </Card.Text>
                    </Card.Body>
                  </div>
                </a>
              </Row>
            ))}
            {cuarta.map((item)=>(
              <Row className="cont-notes">
                <div>
                  <Card className="img-seccion4">
                    <Card.Img variant="middle" src={item.image} width={400}/>
                  </Card>
                </div>
                <a href={"?p=notes&id=" + item.contentId}  rel="noopener noreferrer">
                <div>
                  <Card.Body>  
                    <Card.Title>
                      <strong>{item.title}</strong>
                    </Card.Title>
                      <Card.Text whidth="281px">
                          {item.description}
                      </Card.Text>
                  </Card.Body>
                </div>
                </a>
              </Row>
            ))}
          </Col>
          <Col className="cont-banders">
            <Row>
              <Col>
                <Container>
                  <Carousel fade>
                    {anunciosegunda.map((item)=>(
                      <Carousel.Item interval={2000}>
                        <a href={item.forward}  rel="noopener noreferrer">
                          <div>
                          <img
                            className=""
                            src={item.image}
                            alt=""
                            width="100%"
                          />
                          </div>
                        </a>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </Container>
              </Col>
            </Row>
            <Container>
              <Row  className="cont-bader-2">
                <Col>
                  <Carousel fade>
                    {anuncioquinta.map((item)=>(
                      <Carousel.Item interval={2000}>
                        <a href={item.forward}  rel="noopener noreferrer">
                          <div>
                          <img
                            className=""
                            src={item.image}
                            alt=""
                            width="100%"
                            height="100%"
                          />
                          </div>
                        </a>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
{/*container esta semana, responsive */}
      <Container>
        <Row className="cont-notes-responsive">
          
          <Col>
            <div className="box-estasemana-responsive">
              <p>ESTA SEMANA</p>
            </div>
            { tercera.map((item)=>(
              <Row>
                <Col>
                <a href={"?p=notes&id=" + item.contentId}  rel="noopener noreferrer">
                  <div className="img-seccion4-responsive">
                      <img src={item.image} width={300}/>
                  </div>
                  <div className="box-seccion-month-responsive">
                      <strong>{item.title}</strong>
                      <p>{item.description}</p>
                  </div>
                  </a>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row >
          <Col className="cont-terceranuncio-responsive">
            <Carousel fade>
              { anuncioquinta.map((item)=>(
                <Carousel.Item interval={2000}>
                  <a href={item.forward} rel="noopener noreferrer">
                    <div>
                    <img
                      className=""
                      src={item.image}
                      alt=""
                      width="100%"
                    />
                    </div>
                  </a>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
  {/*puede interesarte responsive */}
      <Container className="interesante-responsive">
        <div className="box-interesante">
          <p>PUEDE INTERESARTE</p>
        </div>
        <Row>
          <Col className="col-interesante-responsive">
            { puedeinteresarte.map((item) => (
              <a href={"?p=notes&id=" + item.contentId}  rel="noopener noreferrer">
              <div className="div-interesante-1-responsive">
                <ul>
                  <li><strong>{item.title}</strong></li>
                </ul>
              </div>
              </a>
            ))}
          </Col>
        </Row>
      </Container>
      <Container className="anuncio-baninf1">
        <Container className="cont-anuncio-baninf1">
         
              <Carousel fade>
                {anuncioprimera.map((item)=>(
                  <Carousel.Item interval={2000}>
                    <a href={item.forward}  rel="noopener noreferrer">
                      <div>
                      <img
                        className=""
                        src={item.image}
                        alt=""
                        width="100%"
                      
                      />
                      </div>
                    </a>
                  </Carousel.Item>
                ))}
              </Carousel>
    
        </Container>
      </Container>
      <Container className="cont-interesante">
        <div className="box-interesante">
          <p>PUEDE INTERESARTE</p>
        </div>
        <Row>
          <Col className="col-interesante">
            {puedeinteresarte.map((item) => (
              <a href={"?p=notes&id=" + item.contentId}  rel="noopener noreferrer">
              <div className="div-interesante-1">
                <ul>
                  <li><strong>{item.title}</strong></li>
                </ul>
              </div>
              </a>
            ))}
          </Col>
          <Col className="col-interesante">
            {puedeinteresarte2.map((item) => (
              <a href={"?p=notes&id=" + item.contentId}  rel="noopener noreferrer">
                <div className="div-interesante-2">
                  <ul>
                    <li><strong>{item.title}</strong></li>
                  </ul>
                </div>
              </a>
            ))}
          </Col>
        </Row>
      </Container>
      <Container className="seccion-5">
        <div className="box-contacto">
          <p>CONTACTO</p>
        </div>
        <Row>
          <Col   className="cont-contacto">
            <div>
              <div className="text-anuncio-nosotros">
                <p><strong>¡ANÚNCIATE CON NOSOTROS!</strong></p>
              </div>
              <div className="cont-tel">
                <a href="tel:+5612818956"  rel="noreferrer">
                  <span> <img id="ico-telefono" src={icotelefono} width={20} alt=""/> 56 1281 8956</span><br></br>
                </a>

                <a href="https://wa.me/5215612818956" rel="noreferrer">
                  <span> <img id="ico-whats" src={icowhatsapp} width={20} alt=""/> 56 1281 8956</span><br></br>
                </a>

                <a href="mailto:ventas@vivesmeralda.com" rel="noreferrer">
                  <span> <img id="ico-email" src={icoemail} width={20} alt=""/> ventas@vivesmeralda.com</span>
                </a>
              </div>
              <div className="cont-textosup-icons">
                <p><strong>Síguenos en nuestras redes sociales:</strong></p>
                <Nav className="icons-redes">
                  <Nav.Link  className="ico-facebook">
                    <img src={blackicofacebook} width={30}alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={blackicoinstagram} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={blackicotwitter} width={30} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                  </Nav.Link>
                </Nav>
              </div>
            </div>
          </Col>
          <Col  className="cont-revistasate">
            <div>
              <div className="text-sate">
                <p><strong>¿Ya conoces Satélite Inside?</strong></p>
              </div>
              <a href="https://sateliteinside.com/"  rel="noopener noreferrer">
                <div className="img-sate">
                  <Image src={linksate} alt="" width="60%" height="60%"fluid/>
                </div>
              </a>
              <div className="text-sub-sate">
                <p>Entérate de lo último en Ciudad Satélite con nuestra revista hermana</p>
              </div>
            </div>
          </Col>
          <Col className="cont-baninf2">
            <Container>
              <div className="cont-baninf2-cont">
                <Carousel fade>
                  {anunciotercera.map((item)=>(
                    <Carousel.Item interval={2000}>
                      <a href={item.forward}  rel="noopener noreferrer">
                        <div>
                        <img
                          className=""
                          src={item.image}
                          alt=""
                          width="100%"
                        />
                        </div>
                      </a>
                    </Carousel.Item>
                  ))}
                </Carousel>
                </div>
            </Container>
          </Col>
        </Row>
      </Container>
   {/*responsive-contacto */}
      <Container className="cont-contacto-responsive">
        <div className="box-contacto">
          <p>CONTACTO</p>
        </div>
        <Row>
          <Col className="cont-nosotros-responsive">
            <div>
              <div className="text-anuncio-nosotros-responsive">
                <p><strong>¡ANÚNCIATE CON NOSOTROS!</strong></p>
              </div>
              <div className="cont-tel-responsivo">
                <span> <img id="ico-telefono" src={icotelefono} width={20} alt=""/> 56 12818956</span><br></br>
                <a href="https://wa.me/5215612818956"  rel="noreferrer">
                  <span> <img id="ico-whats" src={icowhatsapp} width={20} alt=""/> 56 12818956</span><br></br>
                </a>
                <span> <img id="ico-email" src={icoemail} width={20} alt=""/> ventas@vivesmeralda.com</span>
              </div>
              <div className="cont-textosup-icons-responsivo">
                <p><strong>Síguenos en nuestras redes sociales:</strong></p>
              </div>
              <div className="icons-redes">
                <Nav>
                  <Nav.Link  className="">
                    <img src={blackicofacebook} width={30}alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={blackicoinstagram} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={blackicotwitter} width={30} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                  </Nav.Link>
                </Nav>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="cont-revista">
            <div>
              <div className="text-sate">
                <p><strong>¿Ya conoces Satélite Inside?</strong></p>
              </div>
              <a href="https://sateliteinside.com"  rel="noopener noreferrer">
                <div className="img-sate">
                  <Image src={linksate} alt="" width="60%" height="60%" fluid/>
                </div>
              </a>
              <div className="text-sub-sate">
                <p><strong>Entérate de lo último en Ciudad Satélite con nuestra revista hermana</strong></p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="cont-quinto-anuncio">
            <Carousel fade>
              {anuncioquinta.map((item)=>(
                <Carousel.Item interval={2000}>
                  <a href={item.forward}  rel="noopener noreferrer">
                    <div>
                    <img
                      className=""
                      src={item.image}
                      alt=""
                      width="100%"
                    />
                    </div>
                  </a>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
      <Container className="cont-final">
        <Container>
          <Row>
            <Col>
              <div className="text-privacidad">
                <ul>
                  <strong>
                    {/*<li>Quiénes somos</li>*/}
                    <a  href="?a=privacidad"  rel="noopener noreferrer">
                     <li>Aviso de Privacidad</li>
                    </a>
                    {/*<a href="https://vivesmeralda.com/privacidad.pdf" rel="noopener noreferrer">
                      <li>Legales</li>
                    </a>*/}
                  </strong>
                </ul>
              </div>
            </Col>
            <Col>
              <div  className="cont-logo-footer">
                <img src={finallogo} alt=""/>
              </div>
              <div className="text-logo-foot">
                <p><strong>Todos los derechos reservados, ©2024 Vive Esmeralda</strong></p>
              </div>
            </Col>
            <Col>
              <div>
                <Nav className="icons-redes-ultimos">
                  <Nav.Link  className="">
                    <img src={imagef} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imagei} width={30} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                  </Nav.Link>
                  <Nav.Link>
                    <img src={imaget} width={30} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                  </Nav.Link>
                </Nav>
              </div>
              <div className="text-contacto-final">
                <a name="contactofoot" id="contactofoot"><p><strong>Contacto</strong></p></a>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
  {/*container responsive */}
      <Container  className="container-final-responsive">
        <Row>
          <Col className="privacidad-responsive">
            <ul>
              <strong>
                {/*<li>Quiénes somos</li>*/}
                <a  href="?a=privacidad"  rel="noopener noreferrer">
                     <li>Aviso de Privacidad</li>
               </a>
               {/*} <li>Legales</li>*/}
              </strong>
            </ul>
          </Col>
          <Col>
            <div  className="logo-responsive">
              <img src={finallogo} alt="" width={150}/>
            </div> 
            <div>
              <Nav className="icons-redes-ultimos">
                <Nav.Link  className="">
                  <img src={imagef} width={32} alt="" onClick={() => imageClick(window.location.href="https://www.facebook.com/vivesmeralda")}/>
                </Nav.Link>
                <Nav.Link>
                  <img src={imagei} width={32} alt="" onClick={() => imageClick(window.location.href="https://www.instagram.com/viveesmeralda/?hl=es")}/>
                </Nav.Link>
                <Nav.Link>
                  <img src={imaget} width={32} alt="" onClick={() => imageClick(window.location.href="https://twitter.com/viveesmeralda")}/>
                </Nav.Link>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="derechos-reservados-resposive">
            <p><strong>Todos los derechos reservados, ©2024 Vive Esmeralda</strong></p>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default App;
